//导入axios
import axios from 'axios'
//使用element-ui Message做消息提醒
import { MessageBox, Message } from 'element-ui'
//使用vuex管理Token
import store from '@/store'
//获取Token和移除Token的方法
import { getToken } from '@/utils/auth'
//1.创建新的axios 实例
const service = axios.create({
    //公共接口（线下地址、测试地址、线上地址）
    baseURL: process.env.VUE_APP_BASE_API,
    //超时时间，单位是ms
    timeout: 10000
})

//2.请求拦截器
service.interceptors.request.use(
    config => {
        //判断Token是否为true
        if (store.getters.token) {
            //配置请求头
            config.headers['Authorization'] = 'Bearer ' + getToken()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
//3.响应拦截器
service.interceptors.response.use(
    //使用Promise进行处理和响应
    response => {
      const res = response.data
        if (res.code != 200) {
            Message.error(res.msg)
            return Promise.reject(new Error(res.msg || '登录已失效，无法进行操作'))
        }else {
          return res
        }
    },
    error => {
        console.log('err' + error)
        Message({
            message: error.msg,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

//4.导入文件
export default service
