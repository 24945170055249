//http.js
//导入封装好的axios实例
import request from './request'

const http = {

    //Get请求
    get(url,params){
        const config={
            method :'get',
            url:url
        }
        if(params) config.params = params
        return request(config)
    },

    //POST请求
    post(url,params){
        const config = {
            method: 'post',
            url:url
        }
        if(params) config.data = params
        return request(config)
    },

    //删除
    delete(url,params){
        const config = {
            method: 'get',
            url: url
        }
        if (params) config.params = params
        return request(config)
    },

    //修改
    update(url, params) {
        const config = {
            method: 'post',
            url: url
        }
        if (params) config.data = params
        return request(config)
    },

    //查询
    search(url, params) {
        const config = {
            method: 'post',
            url: url
        }
        if (params) config.data = params
        return request(config)
    }
}
//导出
export default http

