import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/solution",
    name: "solution",
    component: () =>
      import("../views/solution/index.vue"),
  },
  {
    path: "/solution1",
    name: "solution1",
    component: () =>
      import("../views/solution/index1.vue"),
  },
  {
    path: "/solution2",
    name: "solution2",
    component: () =>
      import("../views/solution/index2.vue"),
  },
  {
    path: "/solution3",
    name: "solution3",
    component: () =>
      import("../views/solution/index3.vue"),
  },
  {
    path: "/solution4",
    name: "solution4",
    component: () =>
      import("../views/solution/index4.vue"),
  },
  {
    path: "/enablingTechnology",
    name: "enablingTechnology",
    component: () =>
      import("../views/enablingTechnology/index.vue"),
  },
  {
    path: "/enablingTechnology1",
    name: "enablingTechnology1",
    component: () =>
      import("../views/enablingTechnology/index1.vue"),
  },
  {
    path: "/enablingTechnology2",
    name: "enablingTechnology2",
    component: () =>
      import("../views/enablingTechnology/index2.vue"),
  },
  {
    path: "/enablingTechnology3",
    name: "enablingTechnology3",
    component: () =>
      import("../views/enablingTechnology/index3.vue"),
  },
  {
    path: "/productCase",
    name: "productCase",
    component: () =>
      import("../views/productCase/index.vue"),
  },
  {
    path: "/productCase1",
    name: "productCase1",
    component: () =>
      import("../views/productCase/index1.vue"),
  },
  {
    path: "/productCase2",
    name: "productCase2",
    component: () =>
      import("../views/productCase/index2.vue"),
  },
  {
    path: "/productCase3",
    name: "productCase3",
    component: () =>
      import("../views/productCase/index3.vue"),
  },
  {
    path: "/productCase4",
    name: "productCase4",
    component: () =>
      import("../views/productCase/index4.vue"),
  },
  {
    path: "/productCase5",
    name: "productCase5",
    component: () =>
      import("../views/productCase/index5.vue"),
  },
  {
    path: "/productCase6",
    name: "productCase6",
    component: () =>
      import("../views/productCase/index6.vue"),
  },
  {
    path: "/productCase7",
    name: "productCase7",
    component: () =>
      import("../views/productCase/index7.vue"),
  },
  {
    path: "/productCase8",
    name: "productCase8",
    component: () =>
      import("../views/productCase/index8.vue"),
  },
  {
    path: "/productCase9",
    name: "productCase9",
    component: () =>
      import("../views/productCase/index9.vue"),
  },
  {
    path: "/productCase10",
    name: "productCase10",
    component: () =>
      import("../views/productCase/index10.vue"),
  },
  {
    path: "/pressCenter",
    name: "pressCenter",
    component: () =>
      import("../views/pressCenter/index.vue"),
  },
  {
    path: "/pressCenter1",
    name: "pressCenter1",
    component: () =>
      import("../views/pressCenter/index1.vue"),
  },
  {
    path: "/pressCenter2",
    name: "pressCenter2",
    component: () =>
      import("../views/pressCenter/index2.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () =>
      import("../views/aboutUs/index.vue"),
  },
  {
    path: "/aboutUs1",
    name: "aboutUs1",
    component: () =>
      import("../views/aboutUs/index1.vue"),
  },
  {
    path: "/aboutUs2",
    name: "aboutUs2",
    component: () =>
      import("../views/aboutUs/index2.vue"),
  },
  {
    path: "/aboutUs3",
    name: "aboutUs3",
    component: () =>
      import("../views/aboutUs/index3.vue"),
  },
  {
    path: "/aboutUs4",
    name: "aboutUs4",
    component: () =>
      import("../views/aboutUs/index4.vue"),
  },
  {
    path: "/aboutUs5",
    name: "aboutUs5",
    component: () =>
      import("../views/aboutUs/index5.vue"),
  },
  {
    path: "/honor",
    name: "honor",
    component: () =>
      import("../views/aboutUs/honor.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import("../views/search.vue"),
  },
];

const router = new VueRouter({
  routes,
});

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router;
