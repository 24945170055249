import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios' //引入axios
import ElementUI from 'element-ui' //element-ui的全部组件
import 'element-ui/lib/theme-chalk/index.css'//element-ui的css
// main.js
import jsCookie from 'js-cookie'
import waterfall from 'vue-waterfall2'
Vue.use(ElementUI) //使用elementUI
Vue.prototype.$cookie = jsCookie;  // 在页面里可直接用 this.$cookie 调用
// 分页组件
import Pagination from "@/components/Pagination";
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(waterfall)
import 'element-ui/lib/theme-chalk/index.css'
Vue.component('Pagination', Pagination)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
