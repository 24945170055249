<template>
  <div class="page">
    <PageHead @getIsShow="getIsShow" ></PageHead>
    <div class="pagelbt" v-if="!isShow" >
      <!-- <img style="width: 100%" src="../assets/lunbotu.png" alt="" /> -->
      <el-carousel
        :interval="2000"
        arrow="always"
        @change="
          (pre, next) => {
            change(pre, next);
          }
        "
      >
        <el-carousel-item v-for="(item, index) in carouselChartList" :key="index">
          <img style="width: 100%; height: 100%" :src="item.fileUrl" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div v-for="(item, index) in carouselChartList" :class="index===0? 'pageLg':'pageLg'+index" :key="index">
        <img  v-show="pre1 !== index" :src="item.uncheckSrc" alt="" />
        <img v-show="pre1 === index" :src="item.checkSrc" alt="" />
      </div>
<!--      <div class="pageLg1">-->
<!--        <img v-show="pre1 != 1" src="../assets/pageLogoBan2.png" alt="" />-->
<!--        <img v-show="pre1 == 1" src="../assets/pageLogoBan2A.png" alt="" />-->
<!--      </div>-->
<!--      <div class="pageLg2">-->
<!--        <img v-show="pre1 != 2" src="../assets/pageLogoBan3.png" alt="" />-->
<!--        <img v-show="pre1 == 2" src="../assets/pageLogoBan3A.png" alt="" />-->
<!--      </div>-->
<!--      <div class="pageLg3">-->
<!--        <img v-show="pre1 != 3" src="../assets/pageLogoBan4.png" alt="" />-->
<!--        <img v-show="pre1 == 3" src="../assets/pageLogoBan4A.png" alt="" />-->
<!--      </div>-->
<!--      <div class="pageLg4">-->
<!--        <img v-show="pre1 != 4" src="../assets/pageLogoBan5.png" alt="" />-->
<!--        <img v-show="pre1 == 4" src="../assets/pageLogoBan5A.png" alt="" />-->
<!--      </div>-->
<!--      <div class="pageLg5">-->
<!--        <img v-show="pre1 != 5" src="../assets/pageLogoBan6.png" alt="" />-->
<!--        <img v-show="pre1 == 5" src="../assets/pageLogoBan6A.png" alt="" />-->
<!--      </div>-->
    </div>
    <div class="body">
      <div class="first">
        <div class="jjfa">解决方案</div>
        <div class="solution">THE SOLUTION</div>
        <div class="firstContent">
          <div @click="toSolution(item)" v-for="(item,index) in solutionList" :key="index" class="fCon">
            <div class="fimgMB"></div>
            <div class="fimg1">
              <img class="fimg" :src="item.homeSrc" alt="" />
            </div>
            <div class="fConFont">{{item.remark}}</div>
            <div class="font">{{item.title}}</div>
            <div class="font1">{{item.eTitle}}</div>
          </div>
<!--          <div class="fCon">-->
<!--            <div class="fimgMB"></div>-->
<!--            <div class="fimg1">-->
<!--              <img class="fimg" src="@/assets/two.png" alt="" />-->
<!--            </div>-->
<!--            <div class="fConFont">助力政府数字化改革</div>-->
<!--            <div class="font">政府数字化</div>-->
<!--            <div class="font1">GOVERNMENT</div>-->
<!--          </div>-->
<!--          <div class="fCon">-->
<!--            <div class="fimgMB"></div>-->
<!--            <div class="fimg1">-->
<!--              <img class="fimg" src="@/assets/three.png" alt="" />-->
<!--            </div>-->
<!--            <div class="fConFont">智能化基座，助力企业赋能</div>-->
<!--            <div class="font">行业数字化</div>-->
<!--            <div class="font1">ENTERPRISE</div>-->
<!--          </div>-->
<!--          <div class="fCon">-->
<!--            <div class="fimgMB"></div>-->
<!--            <div class="fimg1">-->
<!--              <img class="fimg" src="@/assets/four.png" alt="" />-->
<!--            </div>-->
<!--            <div class="fConFont">传统产业智能化，打造共赢价值</div>-->
<!--            <div class="font">产业数字化</div>-->
<!--            <div class="font1">INDUSTRY</div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="second">
        <div class="alzs">案例展示</div>
        <div class="sRight">
          <div class="solution">THE CASE SHOW</div>
          <div class="more" @click="productCase">
            <div class="gdal">更多案例</div>
            <div>></div>
          </div>
        </div>
        <div class="secondContent" v-if="productCaseList.length>0">
          <div class="sCon"  @click="goMore(productCaseList[item-1])"  v-for="(item, index) in 4" :key="index">
            <img :src="productCaseList[item-1].fileSrc" alt="" />
            <div class="sFont">{{ productCaseList[item-1].title }}</div>
            <div class="sFont1">{{ productCaseList[item-1].introduce }}</div>
            <div class="ljgd" >了解更多</div>
          </div>
        </div>
        <div class="secondContent1" v-if="productCaseList.length>4">
          <div class="sCon" @click="goMore(productCaseList[item+3])" v-for="(item, index) in productCaseList.length-4" :key="index">
            <img :src="productCaseList[item+3].fileSrc" alt="" />
            <div class="sFont">{{ productCaseList[item+3].title }}</div>
            <div class="sFont1">{{ productCaseList[item+3].introduce }}</div>
            <div class="ljgd" >了解更多</div>
          </div>
        </div>
      </div>
      <div class="third">
        <div class="xwrb">新闻热榜</div>
        <div class="sRight">
          <div class="solution">HOT LIST</div>
          <div class="more" @click="pressCenter">
            <div class="gdal">更多新闻</div>
            <div>></div>
          </div>
        </div>
        <div
          class="thirdContent thirdBorder"
          v-for="(item, index) in newsNoticeList"
          :key="index"
        >
          <!-- <div ><img class="leftImg" :src="item.fileSrc" alt="" /></div> -->
          <div class="pleftImg">
            <img class="leftImg" :src="item.fileSrc" alt="" @click="goMoreNews(item)" style="cursor: pointer"/>
            <div v-if="(index+1) === 1" style="background-color: #FF6000" class="titleToLeft">
              {{index+1}}
            </div>
            <div v-if="(index+1) === 2" style="background-color: #FF8001" class="titleToLeft">
              {{index+1}}
            </div>
            <div v-if="(index+1) === 3" style="background-color: #FFC501" class="titleToLeft">
              {{index+1}}
            </div>
          </div>
          <div class="rightCon">
            <div class="title" @click="goMoreNews(item)" style="cursor: pointer">{{ item.title }}</div>
            <div class="tCon">{{ item.introduce }}</div>
            <div class="date">{{ item.sendTime }}</div>
            <div class="dateMore" @click="goMoreNews(item)" style="cursor: pointer">查看详情></div>
          </div>
        </div>
      </div>
      <div class="fourth">
        <div class="gywm">关于我们</div>
        <div class="solution">ABOUT US</div>
        <div class="fourthContent">
          <div class="tLeftCon">
            <div class="fourthImg" @click="toAboutUs" style="cursor: pointer">
              <img src="../assets/pageLogo.png" alt="" />
            </div>
            <div class="tLeftConRight">
              <div class="tLeftConR">计算机软件开发</div>
              <div class="tLeftConR">承接项目外包</div>
              <div class="tLeftConR">技术咨询</div>
              <div class="tLeftConR">技术服务</div>
            </div>
          </div>
          <div class="fourthBorder"></div>
          <div class="tRightCon">
            <div class="trc">
              <div class="trct">山东然茜信息科技有限公司</div>
              <div class="trcdate">成立于2018年01月10日</div>
            </div>
            <div class="trcone">
              业务范围包括：计算机软件的技术开发、技术咨询、技术转让、技术服务;
            </div>
            <div class="trctwo">
              信息系统集成服务;电子产品、电子元器件的销售;
            </div>
            <div class="trctwo">
              计算机、软件及辅助设备、电子设备、计算机网络设备的销售及维修服务;
            </div>
            <div class="trctwo">信息系统集成服务;</div>
            <div class="trctwo">计算机软件开发、销售;软件服务外包。</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Bus from "@/bus.js";
import Footer from "@/views/footer.vue";
import PageHead from "@/views/pageHead.vue";
import {getOpenCarouselChartList,getOpenSolutionList,getProductCaseHomePage,getNewsNoticeHomePage} from "../api/open";
export default {
  components: {
    PageHead,
    Footer,
  },
  data() {
    return {
      second: [
        {
          img: require("@/assets/sone.png"),
          name: "智慧工地大数据平台",
          content:
            "以大数据为基础，云计算及深度学习为手段，实现智慧化管理、智慧化生产、智慧化监控、智慧化服务四化目标。",
          name1: "/productCase4",
        },
        {
          img: require("@/assets/stwo.png"),
          name: "执法预警监督管理平台",
          content:
            "该平台是为巩固完善执法规范化建设长效机制，切实提升公安机关维护社会公平正义的能力和执法公信力。",
          name1: "/productCase8",
        },
        {
          img: require("@/assets/sthree.png"),
          name: "煤矿安全检测执行系统",
          content:
            "采用信息化手段，提高煤矿安全监察行政执法，执法监管工作水平以及执法工作的准确、及时、公正廉洁。",
          name1: "/productCase2",
        },
        {
          img: require("@/assets/sfour.png"),
          name: "工业制冷云",
          content:
            "运用物联网技术，指定工业制冷解决方案，进一步提高冷库的运行效率、降低运营成本。",
          name1: "/productCase7",
        },
      ],
      second1: [
        {
          img: require("@/assets/page智慧用电.png"),
          name: "智慧用电",
          content:
            "该项目实现电力集团公司基层单位项目立项、上级单位审批、回退、会签、审批流程查看等整个项目管理流程",
          name1: "/productCase1",
        },
        {
          img: require("@/assets/page智慧校园.png"),
          name: "智慧校园",
          content:
            "实现数字化办公，对外向学生家长提供各种服务，如：消息通知、请假管理，校园活动，学生动态，教师通讯录，学生定位等各种服务。",
          name1: "/productCase10",
        },
        {
          img: require("@/assets/page智慧农业.png"),
          name: "智慧农业",
          content:
            "将物联网技术运用到传统农业中去，运用传感器和软件通过移动平台或者电脑平台对农业生产进行控制，使传统农业更具有“智慧”",
          name1: "/productCase6",
        },
        {
          img: require("@/assets/page智慧水利.png"),
          name: "智慧水利",
          content:
            "“水利工程补短板，水利工作强监管”是今后工作的总基调。当前，传统水利已难以充分满足新时代经济社会发展所需的专业化、精细化、智能化的管理要求",
          name1: "/productCase5",
        },
      ],
      third: [
        {
          img: require("@/assets/tone.png"),
          title: "国庆节放假通知！",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/ttwo.png"),
          title: "端午节放假通知！",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/tthree.png"),
          title: "春节放假通知！",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
      ],
      carousel: [
        {
          img: require("@/assets/l1.png"),
        },
        {
          img: require("@/assets/l2.png"),
        },
        {
          img: require("@/assets/l3.png"),
        },
        {
          img: require("@/assets/l4.png"),
        },
        {
          img: require("@/assets/l5.png"),
        },
        {
          img: require("@/assets/l6.png"),
        },
      ],
      pre1:0,
      isShow:false,
      carouselChartList:[],
      solutionList:[],
      productCaseList:[],
      newsNoticeList:[],
    };
  },
  mounted() {
    /**
     * 轮播图列表
     */
    getOpenCarouselChartList({}).then(res=>{
      this.carouselChartList = res.data
      if(this.carouselChartList!==null){
        this.carouselChartList.forEach(item=>{
          item.fileUrl = process.env.VUE_APP_BASE_API+item.fileUrl
          item.checkSrc = process.env.VUE_APP_BASE_API+item.checkSrc
          item.uncheckSrc = process.env.VUE_APP_BASE_API+item.uncheckSrc
        })
      }
    })
    getOpenSolutionList({}).then(res=>{
      this.solutionList = res.data
      if(this.solutionList!==null){
        this.solutionList.forEach(item=>{
          item.fileSrc = process.env.VUE_APP_BASE_API+item.fileSrc
          item.mainDrawingSrc = process.env.VUE_APP_BASE_API+item.mainDrawingSrc
          item.homeSrc = process.env.VUE_APP_BASE_API+item.homeSrc
        })
      }
    })
    getProductCaseHomePage({}).then(res=>{
      this.productCaseList = res.rows
      if(this.productCaseList){
        this.productCaseList.forEach(item=>{
          item.fileSrc = process.env.VUE_APP_BASE_API+item.fileSrc
        })
      }
    })
    getNewsNoticeHomePage({}).then(res=>{
      this.newsNoticeList = res.rows
      if(this.newsNoticeList){
        this.newsNoticeList.forEach(item=>{
          item.fileSrc = process.env.VUE_APP_BASE_API+item.fileSrc
        })
      }
    })
  },
  methods: {
    goMoreNews(row) {
      this.$router.push({path:"pressCenter2",query:{id:row.id} });
    },
    toAboutUs() {
      this.$router.push({path:"aboutUs" });
    },
    toSolution(row) {
      if(row.title === "社会服务"){
        this.$router.push({path:'solution',query:{solutionId:row.id}})
      }
      if(row.title === "政府数字化"){
        this.$router.push({path:'solution1',query:{solutionId:row.id}})
      }
      if(row.title === "行业数字化"){
        this.$router.push({path:'solution2',query:{solutionId:row.id}})
      }
      if(row.title === "产品数字化"){
        this.$router.push({path:'solution3',query:{solutionId:row.id}})
      }
    },
    goMore(row) {
      this.$router.push({path:"productCase1",query:{id:row.id} });
    },
    getIsShow(val){
      this.isShow = val
    },
    change(pre) {
      Bus.$emit("CurIndex", pre);
      this.pre1 = pre
    },
    productCase() {
      this.$router.push({ name: "productCase" });
    },
    pressCenter() {
      this.$router.push({ name: "pressCenter" });
    },
    // goMore(item) {
    //   this.$router.push(item.name1);
    // },
    goMore1(item) {
      this.$router.push(item.name1);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
/* .head {
  width: 100%;
  height: 80px;
  background-color: #fff;
}
.headContent {
  width: 1200px;
  height: 80px;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
}
.headLogo {
  padding: 22px 0 0 24px;
}
.headTab {
  margin-left: 196px;
  font-size: 18px;
  align-self: center;
  display: flex;
}
.tabmr {
  margin-right: 32px;
}
.search {
  width: 190px;
  height: 32px;
  border: 1px solid #999;
  align-self: center;
  padding: 5px 0 0 8px;
  border-radius: 20px;
} */
.pagelbt {
  width: 100%;
  height: 100%;
  position: relative;
}
/deep/.el-carousel__container {
  position: relative;
  height: 100vh !important;
}
.body {
  width: 1200px;
  background-color: #fcfcfc;
  margin: 0 auto;
  padding-bottom: 60px;
}
.jjfa {
  font-size: 24px;
  padding: 60px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.solution {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.firstContent {
  margin: 16px 0 0 32px;
  display: flex;
}
.fCon {
  margin-right: 32px;
  position: relative;
}
.fConFont {
  position: absolute;
  top: 172px;
  left: 8px;
  font-size: 14px;
  color: #bfbfbf;
  line-height: 20px;
  z-index: 10;
}
.fimgMB {
  width: 260px;
  height: 200px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #000000 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 20px 0px 20px 0px;
  z-index: 10;
  position: relative;
  transition: all 0.6s;
}
.fimg1 {
  position: absolute;
  z-index: 1;
  top: 0;
  transition: all 0.6s;
}
.fimg {
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.fimgMB:hover + div {
  transform: scale(1.05);
}
.fimgMB:hover {
  transform: scale(1.05);
}
.font {
  font-size: 20px;
  color: #000;
  margin-top: 8px;
  letter-spacing: 1px;
}
.font1 {
  font-size: 16px;
  color: #bfbfbf;
  letter-spacing: 1px;
}
.alzs {
  font-size: 24px;
  padding: 60px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.sRight {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.more {
  display: flex;
}
.more:last-child {
  color: #7d7d7d;
  cursor: pointer;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
  padding-top: 2px;
}
.secondContent {
  margin: 16px 0 0 32px;
  display: flex;
}
.secondContent1 {
  margin: 24px 0 0 32px;
  display: flex;
}
.sCon {
  width: 260px;
  height: 280px;
  background-color: #f8f8f8;
  margin-right: 32px;
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
}
.sCon:hover {
  transform: scale(1.1);
}
.sFont {
  margin: 16px 0 8px 16px;
  font-weight: bold;
  letter-spacing: 1px;
}
.sFont1 {
  font-size: 14px;
  color: #595959;
  margin: 0 20px 0 16px;
  width: 230px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ljgd {
  font-size: 12px;
  color: #7d7d7d;
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
}
.xwrb {
  font-size: 24px;
  padding: 60px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.thirdContent {
  display: flex;
  margin: 16px 0px 0 32px;
  border-bottom: 1px solid #d8d8d8;
}
.thirdBorder {
  border: none;
}
.dateMore {
  color: #595959;
  position: absolute;
  bottom: 16px;
  right: 24px;
  letter-spacing: 1px;
  cursor: pointer;
}
.leftImg {
  width: 240px;
  height: 140px;
  margin: 0 24px 16px 0;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.rightCon {
  width: 100%;
  height: 156px;
  position: relative;
}
.title {
  width: 890px;
  font-size: 24px;
  margin-bottom: 16px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.tCon {
  width: 890px;
  margin-right: 16px;
  color: #595959;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.date {
  color: #595959;
  position: absolute;
  bottom: 16px;
  letter-spacing: 1px;
}
.gywm {
  font-size: 24px;
  padding: 60px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.fourthContent {
  display: flex;
  margin: 16px 32px;
}
.tLeftCon {
  display: flex;
  width: 55%;
}
.fourthImg {
  align-self: center;
  margin: 0 68px;
}
.tLeftConRight {
  justify-content: end;
}
.tLeftConR {
  width: 160px;
  height: 60px;
  background: linear-gradient(180deg, #f6f6f6 0%, #ffffff 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0px 20px 0px 20px;
  margin-bottom: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  line-height: 60px;
}
.fourthBorder {
  width: 2px;
  background-color: #d8d8d8;
  margin-bottom: 16px;
}
.tRightCon {
  width: 45%;
}
.trc {
  display: flex;
}
.trct {
  padding-left: 24px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
}
.trcdate {
  color: #595959;
  letter-spacing: 2px;
  align-self: end;
}
.trcone {
  color: #595959;
  letter-spacing: 2px;
  padding-left: 24px;
  margin-top: 32px;
  line-height: 2rem;
}
.trctwo {
  color: #595959;
  letter-spacing: 2px;
  padding-left: 24px;
  line-height: 2rem;
}
/deep/ .el-carousel__arrow {
  width: 80px !important;
  height: 80px !important;
}
[class*=" el-icon-"],
[class^="el-icon-"] {
  font-size: 50px;
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 64px 16px !important;
}

/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  width: 45px;
  height: 45px;
  background: transparent;
  /* border: 1px solid #ffffff; */
  /* border-radius: 50%; */
  opacity: 0.5;
  /* background-image: url("/src/assets/logo.png"); */
  margin-right: 15px;
}
/* .el-carousel__button {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}
.is-active .el-carousel__button {
  background: #3f8ec8;
} */
.pageLg {
  position: absolute;
  bottom: 50px;
  z-index: 5;
  transform: translate(-50%);
  left: 37.5%;
}
.pageLg1 {
  position: absolute;
  bottom: 50px;
  z-index: 5;
  transform: translate(-50%);
  left: 42.5%;
}
.pageLg2 {
  position: absolute;
  bottom: 50px;
  z-index: 5;
  transform: translate(-50%);
  left: 47.5%;
}
.pageLg3 {
  position: absolute;
  bottom: 50px;
  z-index: 5;
  transform: translate(-50%);
  left: 52.5%;
}
.pageLg4 {
  position: absolute;
  bottom: 50px;
  z-index: 5;
  transform: translate(-50%);
  left: 56.8%;
}
.pageLg5 {
  position: absolute;
  bottom: 50px;
  z-index: 5;
  transform: translate(-50%);
  left: 61%;
}
.titleToLeft{
  text-align:center;
  width: 25px;
  height: 25px;
  background-color: red;
  margin-top: -160px;
  line-height: 25px;
  position: absolute;
  color: white;
  border-bottom-right-radius: 10px;
}
</style>
