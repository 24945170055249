<template>
  <div>
    <div class="footer">
      <div class="footerContent">
        <div class="footerLeftContent">
          <div class="footerTitle">
            <div class="footerTitleCon">
              <div class="footerTopTitle">解决方案</div>
              <div v-for="(item,index) in solutionList" :key="index">
                <div class="footerBotCon1" @click="toSolution(item)">{{item.title}}</div>
              </div>
              <!-- <div class="footerBotCon" @click="solution">社会服务</div>
              <div class="footerBotCon1" @click="solution1">政府数字化</div>
              <div class="footerBotCon1" @click="solution2">行业数字化</div>
              <div class="footerBotCon1" @click="solution3">产品数字化</div> -->
            </div>
            <div class="footerTitleCon">
              <div class="footerTopTitle">技术赋能</div>
              <div v-for="(item,index) in technologyEnabling" :key="index">
                <div class="footerBotCon1" @click="enablingTechnology(item)">{{item.title}}</div>
              </div>
              <!-- <div class="footerBotCon" @click="enablingTechnology">
                人工智能
              </div>
              <div class="footerBotCon1" @click="enablingTechnology1">
                云计算
              </div>
              <div class="footerBotCon1" @click="enablingTechnology2">
                大数据
              </div>
              <div class="footerBotCon1" @click="enablingTechnology3">IOT</div> -->
            </div>
            <div class="footerTitleCon">
              <div class="footerTopTitle">产品案例</div>
              <div v-for="(item,index) in productCaseList" :key="index">
                <div v-if="item.title.length < 6" class="footerBotCon1" @click="goMore(item)">{{item.title}}</div>
              </div>
            </div>
            <div class="footerTitleCon">
              <div class="footerTopTitle">新闻中心</div>
              <div class="footerBotCon" @click="pressCenter">新闻快讯</div>
              <div class="footerBotCon1" @click="pressCenter1">通知公告</div>
            </div>
            <div class="footerTitleCon">
              <div class="footerTopTitle">关于我们</div>
              <div class="footerBotCon" @click="aboutUs">公司介绍</div>
              <div class="footerBotCon1" @click="aboutUs1">公司荣誉</div>
              <div class="footerBotCon1" @click="aboutUs2">能力体系</div>
              <div class="footerBotCon1" @click="aboutUs3">发展历程</div>
              <!-- <div class="footerBotCon1" @click="aboutUs4">合作伙伴</div> -->
              <!-- <div class="footerBotCon1" @click="aboutUs5">加入我们</div> -->
            </div>
          </div>
        </div>
        <div class="footerCenterBorder"></div>
        <div class="footerRightContent">
          <div class="">
            <img style="width:155px;height155px" src="../assets/WX.png" alt="" />
            <div class="footerRightContentWX">然茜科技官方微信</div>
            <div class="footerRightContentPhone">
              <span>服务热线：0531-88789622</span>
<!--              <span style="margin-left: 40px">0531-88789733</span>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footerBottom">
      <div>@2018 山东然茜信息科技有限公司 版权所有。 鲁ICP备18016140号</div>
      <div style="margin-left: 110px">
        企业邮箱：rancii@126.com｜公司地址：山东省济南市高新区齐鲁软件园C座一楼B107室
      </div>
    </div>
  </div>
</template>

<script>
import {getOpenSolutionList,getOpenTechnologyEnablingList,getProductCaseHomePage} from "../api/open";
export default {
  data() {
    return {
      solutionList: [],
      technologyEnabling: [],
      productCaseList:[]
    };
  },
  mounted() {
    getOpenSolutionList({}).then(res=>{
      this.solutionList = res.data
      if(this.solutionList!==null){
        this.solutionList.forEach(item=>{
          item.fileSrc = process.env.VUE_APP_BASE_API+item.fileSrc
          item.mainDrawingSrc = process.env.VUE_APP_BASE_API+item.mainDrawingSrc
          item.homeSrc = process.env.VUE_APP_BASE_API+item.homeSrc
        })
      }
    })
    getOpenTechnologyEnablingList({}).then(res=>{
      this.technologyEnabling = res.data
      if(this.technologyEnabling!==null){
        this.technologyEnabling.forEach(item=>{
          item.fileSrc = process.env.VUE_APP_BASE_API+item.fileSrc
          item.blackSrc = process.env.VUE_APP_BASE_API+item.blackSrc
        })
      }
    })
    getProductCaseHomePage({}).then(res=>{
      this.productCaseList = res.rows
      if(this.productCaseList){
        this.productCaseList.forEach(item=>{
          item.fileSrc = process.env.VUE_APP_BASE_API+item.fileSrc
        })
      }
    })
  },
  methods: {
    toSolution(row) {
      if(row.title === "社会服务"){
        this.$router.push({path:'solution',query:{solutionId:row.id}})
      }
      if(row.title === "政府数字化"){
        this.$router.push({path:'solution1',query:{solutionId:row.id}})
      }
      if(row.title === "行业数字化"){
        this.$router.push({path:'solution2',query:{solutionId:row.id}})
      }
      if(row.title === "产品数字化"){
        this.$router.push({path:'solution3',query:{solutionId:row.id}})
      }
    },
    enablingTechnology(row) {
      this.$router.push({ path: "enablingTechnology",query:{enablingId:row.id} });
    },
    goMore(row) {
      this.$router.push({path:"productCase1",query:{id:row.id} });
    },
    solution() {
      this.$router.push({ name: "solution" });
    },
    solution1() {
      this.$router.push({ name: "solution1" });
    },
    solution2() {
      this.$router.push({ name: "solution2" });
    },
    solution3() {
      this.$router.push({ name: "solution3" });
    },
    // enablingTechnology() {
    //   this.$router.push({ name: "enablingTechnology" });
    // },
    enablingTechnology1() {
      this.$router.push({ name: "enablingTechnology1" });
    },
    enablingTechnology2() {
      this.$router.push({ name: "enablingTechnology2" });
    },
    enablingTechnology3() {
      this.$router.push({ name: "enablingTechnology3" });
    },
    pressCenter() {
      this.$router.push({ name: "pressCenter" });
    },
    pressCenter1() {
      this.$router.push({ name: "pressCenter1" });
    },
    aboutUs() {
      this.$router.push({ name: "aboutUs" });
    },
    aboutUs1() {
      this.$router.push({ name: "aboutUs1" });
    },
    aboutUs2() {
      this.$router.push({ name: "aboutUs2" });
    },
    aboutUs3() {
      this.$router.push({ name: "aboutUs3" });
    },
    aboutUs4() {
      this.$router.push({ name: "aboutUs4" });
    },
    aboutUs5() {
      this.$router.push({ name: "aboutUs5" });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.footer {
  height: 300px;
  background: #373737;
  padding: 60px 32px 40px 32px;
  display: flex;
}
.footerContent {
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.footerLeftContent {
  width: 55%;
}
.footerTitle {
  display: flex;
}
.footerCenterBorder {
  width: 2px;
  background-color: #595959;
}
.footerRightContent {
  width: 45%;
  padding-left: 60px;
}
.footerTitleCon {
  margin-right: 18px;
}
.footerTopTitle {
  font-size: 20px;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 32px;
  text-align: center;
}
.footerBotCon {
  text-align: center;
  color: #d9d9d9;
  margin-top: 32px;
  letter-spacing: 1px;
  cursor: pointer;
}
.footerBotCon1 {
  text-align: center;
  color: #d9d9d9;
  margin-top: 8px;
  letter-spacing: 1px;
  cursor: pointer;
}
.footerRightContentWX {
  color: #d9d9d9;
  margin-top: 16px;
  /* margin-left: 8px; */
  letter-spacing: 1px;
}
.footerRightContentPhone {
  margin-top: 55px;
  color: #d9d9d9;
  letter-spacing: 1px;
}
.footerBottom {
  height: 72px;
  background: #242323;
  display: flex;
  color: #bfbfbf;
  line-height: 72px;
  justify-content: center;
}
</style>
