import http from '@/utils/http'
//轮播图
export function getOpenCarouselChartList(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getOpenCarouselChartList',params)
}
//解决方案
export function getOpenSolutionList(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getOpenSolutionList',params)
}
//首页产品案例
export function getProductCaseHomePage(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getProductCaseHomePage',params)
}

//首页新闻
export function getNewsNoticeHomePage(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getNewsNoticeHomePage',params)
}


//技术赋能
export function getOpenTechnologyEnablingList(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getOpenTechnologyEnablingList',params)
}

//解决方案详情
export function getSolution(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getSolution',params)
}

//解决方案分类
export function getSolutionClassificationPage(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getSolutionClassificationPage',params)
}

//技术赋能详情
export function getTechnologyEnabling(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getTechnologyEnabling',params)
}

//技术赋能分类列表
export function getTechnologyEnablingClassList(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getTechnologyEnablingClassList',params)
}

//产品列表
export function getProductCaseList(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getProductCaseList',params)
}

//产品详情
export function getProductCase(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getProductCase',params)
}

//新闻列表
export function getNewsNoticeList(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getNewsNoticeList',params)
}

//新闻详情
export function getNewsNotice(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getNewsNotice',params)
}

//案例上下条数
export function getNext(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getNext',params)
}

//通知公告
export function getNewsNoticePageListByType(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getNewsNoticePageListByType',params)
}

//新闻列表
export function NewsList(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/openPhone/getNewsNoticeList',params)
}

//公司荣誉
export function getCorporateHonorsList(params){
    // return http.post('请求服务器的接口',前端传过来的数据)
    return http.post('/web/open/getCorporateHonorsList',params)
}
